import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const AboutPage: React.FC = () => (
    <Layout>
        <SEO title="About" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">About</h1>
                <p>
                    <strong>Autism Success in Tech</strong> offers resources to
                    help autistic people achieve success in the tech industry.{" "}
                </p>
                <ul>
                    <li>Technical training</li>
                    <li>Social events</li>
                    <li>Professional networking</li>
                    <li>Improve public perception of autism</li>
                </ul>
                <p>
                    We've been planning to open an{" "}
                    <Link to="/educational-center/">educational center</Link>{" "}
                    with special outreach to autistic people, but the project is
                    on hold due to the pandemic.
                </p>
                <h2 className="title is-2">Employment</h2>
                <p>
                    As many as 85% of people with Asperger's are unemployed or
                    underemployed.<sup>1</sup> Many of us have trouble
                    communicating and deal with severe isolation among many
                    other challenges, despite having high levels of skill and
                    the ability to be contributing members of society. The tech
                    industry is full of autistic people, but many of us are
                    still vulnerable to falling through cracks in the system.
                </p>

                <p>
                    This project attempts to create environments and resources
                    that provide social opportunities, technical training, and
                    mentorship for autistic people who are having trouble
                    finding a path into society.
                </p>
                <h2 className="title is-2">Innovation</h2>
                <p>
                    <strong>Autistic minds help drive innovation.</strong> By
                    investing in support and training for autistic people,
                    society is investing in technological progress and
                    innovation.
                </p>
                <p>
                    We are found at all levels of the workforce from entry-level
                    to CEOs and <em>especially startup founders</em>.
                </p>
                {/*
                <blockquote>
                    <p>
                        "Who do you think made the first stone spear? That
                        wasn't the yakkity yaks sitting around the campfire. It
                        was some Asperger sitting in the back of a cave figuring
                        out how to chip rocks into spearheads. Without some
                        autistic traits you wouldn't even have a recording
                        device to record this conversation on."
                    </p>
                    <p>
                        &mdash;Temple Grandin<sup>2</sup>
                    </p>
                </blockquote>
                */}
                <h2 className="title is-2">Success</h2>
                <p>
                    "Success" means something different to each person. Some
                    people just want to fit into a comfortable niche somewhere,
                    and others want to change the world and make it a better
                    place. The most important thing is to help people find doors
                    into society, and this project attempts to create new doors.
                </p>
                <p>
                    By joining this project and lending a helping hand to other
                    people on the autism spectrum, you can help make it even
                    more useful.
                </p>
                <p>
                    <Link to="/faqs/" className="button is-link is-large">
                        Frequently Asked Questions
                    </Link>
                </p>
                <hr />
                <h2 className="title is-4">Footnotes</h2>
                <p>
                    <small>
                        <sup>1</sup>
                        <a href="http://www.syracuse.com/news/index.ssf/2013/11/experts_expect_more_employees_with_aspergers_in_the_workplace.html">
                            Experts: Expect more employees with Asperger's in
                            the workplace
                        </a>
                    </small>
                </p>
                {/*
                <p>
                    <small>
                        <sup>2</sup>{" "}
                        <a
                            href="https://www.wsj.com/articles/SB10001424052748703525704575061123564007514"
                            rel="nofollow"
                        >
                            Interview
                        </a>
                    </small>
                </p>
                            */}
            </div>
        </section>
    </Layout>
);

export default AboutPage;
